import Button from '@/components/buttons/Button'
import _get from 'lodash/get'
import { parseDate } from '@/utilities/Date/parse'
import { actionItemStore } from '@/services/BoardActionItems/store'
import moment from 'moment'

export const methods = {
  determineRows: function(list) {
    const dateFormat = 'LL'

    let filteredList = list

    if (this.statusFilter !== 'all') {
      filteredList = filteredList.filter(
        f =>
          f.actionItemStatus !== undefined &&
          f.actionItemStatus &&
          f.actionItemStatus.codeName === this.statusFilter
      )
      if (this.isDebug === true) console.debug('filtered = ' + JSON.stringify(filteredList))
    }

    var rows = filteredList.map(entry => {
      const tempCreatedDate = _get(entry, 'createdDate', moment('01/01/1900').format(dateFormat))
      const createdDate = tempCreatedDate
        ? parseDate(tempCreatedDate, dateFormat)
        : moment('01/01/1900').format(dateFormat)

      const assignedTo = _get(entry, 'assignedToIdentityInformation.name', '')
      const title = _get(entry, 'title', '')
      const priority = _get(entry, 'actionItemPriority.name', '')

      const tempCompletedDate = _get(
        entry,
        'completeByDate',
        moment('01/01/1900').format(dateFormat)
      )
      const completeByDate = tempCompletedDate
        ? parseDate(tempCompletedDate, dateFormat)
        : moment('01/01/1900').format(dateFormat)

      return {
        title,
        assignedTo,
        priority,
        createdDate: {
          date: createdDate,
          format: dateFormat
        },
        completeByDate: {
          date: completeByDate,
          format: dateFormat
        },
        view: {
          component: Button,
          props: {
            onClick: () => {
              this.processDetail(entry, true)
            },
            text: 'View'
          }
        },
        edit: {
          component: Button,
          props: {
            onClick: () => {
              this.processDetail(entry, false)
            },
            text: 'Edit'
          }
        }
      }
    })

    this.rows = rows
  },

  async reload() {
    console.debug('in reload...')
    this.loading = true

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    await actionItemStore
      .dispatch('getActionItemList', {
        hoaID: this.hoaId,
        sourceBoardPackageID: null
      })
      .then(({ list }) => {
        this.determineRows(list)
        this.loading = false
      })

    loadingComponent.close()

    this.loading = false
  },

  createActionItemModal() {
    this.toggle = true
  },

  processDetail(actionItem, isView) {
    if (actionItem) {
      //const path = `/actionItemTabs/${actionItem.actionItemID}/${actionItem.title}/${isView}/actionItemDetails#tab`
      const path = `/actionItemTabs/${actionItem.actionItemID}/${isView}/actionItemDetails#tab`

      this.$router.push(path)
    }
  }
}
